:root {
  --color-primary: rgb(78, 61, 224);
  --color-primary-dark: rgb(44, 31, 153);
  --color-primary-light: rgb(99, 85, 225);
  --background-accent-color: #ecedf1;
  --focus-color: var(--color-primary);
  --pane-padding-default: 1rem;
  --color-warning: rgb(236, 21, 21);
  --color-caution: rgb(255, 225, 185);
  --outside-border-radius: 4px;
  --big-border-radius: 4px;
  --border-radius: 4px;
}



@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

* {
  font-family: 'Open Sans';
}

.space-between {
  justify-content: space-between;
}

.grey {
  color: rgba(0, 0, 0, .5);
}



::-webkit-scrollbar {
  width: 6px;

}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 100px;
  background: var(--background-accent-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background: #cfd1d7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bdbec1;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  background-color: var(--background-accent-color);
  overflow: hidden;

}

.alerts-container {
  left: calc(50% - 20em);
  position: absolute;
  width: 40em;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.alert {
  font-size: .9rem;
  width: 40em;

  margin: auto;
  z-index: 5;
  margin-bottom: .5rem;

  height: 4.5em;
  box-sizing: border-box;
  flex-shrink: 0;
  border-radius: 6px;

  text-align: left !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid;
  padding: .6rem 1rem;
  animation: slide-in .4s;

}



@keyframes slide-in {
  0% {
    transform: translateY(-200%);
  }
}


.alert.dismissed {
  animation: slide-out .4s;
}

@keyframes slide-out {
  100% {
    transform: translateY(-200%);
  }
}

.alert.error {
  color: rgb(172, 0, 0);
  background-color: rgb(255, 185, 185);
}

.alert.error .action-button {
  background-color: rgb(172, 0, 0);
}

.alert.warning {
  color: rgb(172, 98, 0);
  background-color: rgb(255, 227, 185);
}

.alert.warning .action-button {
  background-color: rgb(172, 98, 0);
}

.alert.success {
  color: rgb(3, 100, 0);
  background-color: rgb(213, 255, 209);
}

.alert.success .action-button {
  background-color: rgb(3, 100, 0);
}

.alert.info {
  color: var(--color-primary);
  background-color: rgb(228, 223, 247);
}

.alert .dismiss {
  cursor: pointer;
}

.alert .right {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.alert .left {
  display: flex;
  align-items: center;
}

.alert i {
  font-size: 1.7rem;
  margin-right: .7rem;
}

.alert .action-button {
  margin: 0;
  min-width: 5em;
}

.alert .action-button.secondary {
  background-color: unset;
  color: inherit;
}

.add-friend .input-wrapper {
  outline: none !important;
}

.action-bar {
  display: flex;
  /* flex-direction: column; */

}

.action-bar .action-button {
  margin: 0;
}



.alert .action-bar {
  display: flex;
  column-gap: .2rem;
}

.alert .title {
  font-weight: 700;
  margin-right: .5rem;
  text-align: left;
}

.alert .dismiss:empty {
  display: none;
}

a {
  color: var(--color-primary);
  text-decoration: none;
}



a:hover {
  text-decoration: underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {



  height: 100vh;
  width: 100vw;

}

#app-outer {
  height: 100vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
}

#chat {

  height: 100%;
  padding: .5rem;
  min-width: 0;
  max-height: calc(100% - 4rem);
  column-gap: .5rem;
  box-sizing: border-box;
}

.bootstrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.authenticate {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  column-gap: 10rem;

}

.logo {
  color: var(--color-primary);
  font-weight: 800;
  font-size: 1.3rem;
  /* background: -webkit-linear-gradient(90deg, var(--color-primary), var(--color-primary-dark));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.logo.animated {
  cursor: pointer;
}

.logo.animated>a {

  display: inline-block;

}

.logo:hover>a:nth-child(0) {
  animation: logo-letter-animation .5s forwards;
}

.logo:hover>a:nth-child(1) {
  animation: logo-letter-animation .5s .1s forwards;
}

.logo:hover>a:nth-child(2) {
  animation: logo-letter-animation .5s .2s forwards;
}

.logo:hover>a:nth-child(3) {
  animation: logo-letter-animation .5s .3s forwards;
}

.logo:hover>a:nth-child(4) {
  animation: logo-letter-animation .5s .4s forwards;
}

.logo:hover>a:nth-child(5) {
  animation: logo-letter-animation .5s .5s forwards;
}

.logo:hover>a:nth-child(6) {
  animation: logo-letter-animation .5s .6s forwards;
}

.logo:hover>a:nth-child(7) {
  animation: logo-letter-animation .5s .7s forwards;
}

.logo:hover>a:nth-child(0) {
  animation: logo-letter-animation .5s .8s forwards;
}


@keyframes logo-letter-animation {

  50% {
    transform: translateY(-.4em);
  }

}

.authpage-logo {
  margin: auto 0;
  font-size: 3rem;


  height: fit-content;

}

#chat {
  display: flex;
}

.center {
  align-items: center;
  justify-content: center;
}

.channels-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: .9rem;
  color: #1f1f1f;
  margin-bottom: .4rem;
  margin-top: 0.25rem;
  padding: 0 .8rem;
  font-weight: 500;
}

.new-channel {
  cursor: pointer;
  font-size: 1.4rem;
}

.channel {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr 16em;
  height: 100%;
  width: 100%;
  overflow: hidden;

}

.channel.loading .messages {
  overflow: hidden !important;
}

.no-message-history {
  display: flex;

  flex-direction: column;

  color: rgba(0, 0, 0, .5);
}

.no-message-history .title {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 2rem;
}

.no-message-history .title img {
  width: 6rem;
  border-radius: 50%;
}

.channel-top-name {
  display: flex;
  align-items: center;
}

.channel-top-name .input-wrapper,
.channel-top-name input {
  padding: .2rem !important;
  font-size: .96em;
  min-height: 1rem !important;
  outline: none !important;
}


.channel-top-name img {
  margin-right: .5rem;
  --size: 2rem;
  margin-top: .08rem;
  height: var(--size);
  width: var(--size);
  flex-shrink: 0;
  border-radius: 50%;
  background-color: lightgray;
}

.channel-info {
  width: 16em;
  box-sizing: border-box;
  padding: 1rem;
  padding-top: 0;
  grid-column: 2;
  grid-row: 2;
}

.messages-outer {
  grid-column: 1;
  grid-row: 2;
  display: flex;
  width: 100%;
  flex-direction: column;
  min-height: 0;
}

#topbar {
  align-items: center;
  padding: 0 .5rem;
  margin: 0 .5rem;
  display: flex;
  justify-content: space-between;
  height: 4rem;
  box-sizing: border-box;
  background-color: white;
  border-bottom-left-radius: var(--outside-border-radius);
  border-bottom-right-radius: var(--outside-border-radius);
  animation: topbar-in 1s;
}

.pane-topbar {
  display: flex;
  align-items: center;
  height: 2.8rem;
  box-sizing: border-box;
  justify-content: space-between;
  flex-shrink: 0;
  margin-right: 1.8rem;
  padding-bottom: 1rem;



}

@keyframes topbar-in {
  0% {
    transform: translateY(-100%);
  }
}

.i-link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
}



#topbar .settings {
  transition: transform 300ms;
}

#topbar .settings:hover {

  text-decoration: none;
  transform: rotate(15deg);
}

#sidebar {

  display: flex;

  flex-direction: column;
  background-color: white;
  padding: .8rem .8rem;

  border-radius: var(--outside-border-radius);

  animation: sidebar-in 1s;
  width: fit-content;
  min-width: fit-content;
  overflow: hidden;
}

@keyframes sidebar-in {
  0% {
    transform: translateX(-100%);
  }
}

#channels {
  display: flex;
  flex-direction: column;
  padding: 4px;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 0;
  margin-bottom: .9rem;
  height: 100%;
  max-height: calc(100% - 3rem);
}

#channels>a {
  display: block;

}

.topbar-right {
  display: flex;
  column-gap: 1rem;
}



.profile-dropdown-menu {
  position: absolute;
  width: calc(100% + 2px);
  height: 0em;
  left: -1px;
  top: calc(100%);
  overflow: hidden;
  background-color: white;
  z-index: 10;
  border: 1px solid lightgray;
  border-top: none;
  box-sizing: border-box;

  visibility: hidden;
  transition: height 200ms, visibility 200ms;

  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: .5rem;
  padding-top: 0;
}

.profile-dropdown i {

  transition: transform .3s cubic-bezier(.5, -0.17, .35, 1.29);
}

.profile-dropdown-menu .action-button {
  margin: 0;
  margin-bottom: .2rem;
  background-color: ;
}

.profile-dropdown.true .profile-dropdown-menu {
  visibility: visible;

  height: 11.7em;
}

.profile-dropdown>div.smallname {
  display: flex;
  align-items: center;


}

.profile-dropdown .pfp {
  margin-right: 1rem;
}

.profile-dropdown {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 13em;
  margin-bottom: .12rem;
  padding: .5em .5rem;
  box-sizing: border-box;
  height: 3.2em;
  border-radius: var(--big-border-radius);
  cursor: pointer;
  border: 1px solid lightgray;
  transition: border-radius .5s;
}

.profile-dropdown.true {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transition: border-radius 0s;
}

.profile-dropdown:hover {}

.pfp-editor {}


.profile-viewer {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background-color: #0d122266;
}

.profile-viewer .profile {
  width: 19em;
  animation: form-in .4s;
}

.profile {
  position: relative;
  padding: 1rem;
  border: 1px solid lightgray;
  border-radius: var(--outside-border-radius);
  position: relative;
  overflow: hidden;
  background-color: white;


}

.profile .top {
  position: relative;
  display: flex;
  align-items: center;
}



.profile .display-name {
  font-size: 1.3rem;
  font-weight: 600;
}

.profile .name {
  margin-left: .8rem;
}

.fallback-picture {
  position: relative;
}

.fallback-picture .letter {
  display: block;
  color: white;
  font-size: 1rem;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
}

.profile .pfp {
  margin-bottom: 1rem;
}

.profile .bio {
  white-space: pre-line;
  margin-top: .5rem;
  margin-bottom: 1rem;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  min-height: 3rem;
  -webkit-box-orient: vertical;

  border-radius: 4px;
  padding: .2rem .2rem;
}

.profile .bg-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 3em;
  z-index: 0;
  background-color: #ecedf1;
}

.profile-viewer .profile .bg-img {
  height: 4em;
}

.profile-viewer .top {
  margin-top: 1.2em;
}

.profile .bg-img img {
  display: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile h4,
h5 {
  margin: 0;
  margin-bottom: 0rem;
  font-size: 1rem;
  font-weight: 500;
}



.pfp img,
.pfp .img {

  --size: 2.2rem;
  height: var(--size);
  width: var(--size);
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 50%;
  background-color: lightgray;

}

.pfp-editor {
  cursor: pointer;
}

.profile .pfp img,
.profile .pfp .img {
  margin-right: .5rem;
  --size: 6.6rem;
  height: var(--size);
  width: var(--size);
  flex-shrink: 0;
  border-radius: 50%;
  background-color: lightgray;
  outline: 7px solid white;
}

.pfp-editor:hover img {
  cursor: pointer;

  position: relative;
}

.pfp-editor:hover::after {
  content: '';
  color: white;
  position: absolute;
  font-weight: 500;
  background-color: #0d122266;
  border-radius: 50%;
  width: 6.6em;
  height: 6.6em;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pfp-editor input {
  display: none;
}

#accountEditForm .headline {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#profileEditForm .profile {
  margin-bottom: 1rem;
}


.profile-dropdown .smallname img {
  margin-right: .5rem;
  --size: 2.2rem;
  height: var(--size);
  width: var(--size);
  flex-shrink: 0;
  border-radius: 50%;
  background-color: lightgray;
}

video {
  size: 10em;
  width: 10em;
}

.profile-dropdown {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.profile-dropdown img {
  margin-right: .5rem;
  --size: 3.2rem;
  height: var(--size);
  width: var(--size);
  flex-shrink: 0;
  border-radius: 50%;
  background-color: lightgray;
}

.profile-small {
  display: flex;
  align-items: center;
  margin-bottom: .5rem;
  user-select: none;
}



.profile-small .pfp {

  flex-shrink: 0;
  outline: none !important;
  margin-right: .85rem;
  --size: 2.2rem;
  height: var(--size);
  width: var(--size);
  z-index: 0;
  border-radius: 50%;
  background-color: lightgray;
  position: relative;
}

.profile .pfp {
  position: relative;
}

.profile .pfp .activity-status {
  width: 1.5em;
  height: 1.5em;
  outline: 5px white solid;
  right: .5rem;
  bottom: .5rem;

  border-radius: 50%;
  position: absolute;
  background-color: lightgray;
  transition: background-color .2s;
}

.last-message {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 11.1em;
}

.profile-small .activity-status {
  width: .6em;
  height: .6em;
  outline: 4px white solid;
  right: 0;
  bottom: 0;

  border-radius: 50%;
  position: absolute;
  background-color: lightgray;
  transition: background-color .2s;
}

.activity-status.online {
  background-color: #42ce59 !important;
}

.activity-status.away {
  background-color: #f7b500 !important;
}

.activity-status.none {
  display: none;
}


.profile-small img {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: lightgray;
}

.profile-small.w-interact {
  cursor: pointer;

  display: flex !important;
  min-height: 3rem;
  max-height: 3rem;
  box-sizing: border-box;
  align-items: center;

  border-radius: 6px;

  padding: .4rem .5rem;
  color: #494e61;
  margin-bottom: .15rem;
  background-color: white;
}



.f-force {
  outline: 1px solid #ecedf1 !important;
  transition: outline 200ms, outline-color 0ms;


}

#pane .filter-tabs {
  margin-bottom: 1rem;
}

#sidebar .filter-tabs {
  margin-left: 0.4rem;
  margin-bottom: .9rem;
  padding-right: .4rem;
}

.filter-tabs {
  user-select: none;
  display: flex;
  column-gap: .35em;
}

.filter-tabs.more-space {}

.filter-tabs .tab {
  font-size: .9rem;
  background-color: var(--background-accent-color);
  min-width: 2.5rem;
  padding: .2rem 1rem;
  border-radius: 40px;
  text-align: center;
  cursor: pointer;
  transition: transform, outline 50ms;
  outline: 2px solid #00000000;
  margin: -2px -2px -2px -2px;
  border: 2px rgb(255, 255, 255) solid;
}

.tab.green {

  font-weight: 500;
  background-color: white;
  color: #42ce59;
  outline: 2px solid #42ce59;
  margin-left: 2px;
}



.empty-skeleton-text:empty {
  background-color: var(--background-accent-color);
  background: linear-gradient(90deg, rgb(247, 247, 247), rgb(221, 221, 221), rgb(247, 247, 247));
  background-size: 200%;
  min-width: 10rem;
  min-height: 1.2em;
  border-radius: 2px;
  animation: skeleton-background 2s infinite;
}

@keyframes skeleton-background {
  0% {

    background-size: 200%;
  }

  50% {

    background-size: 200%;
    background-position: 200%, 0;
  }
}

.shake-error {
  animation: shake .3s;
}

@keyframes shake {
  25% {
    transform: translateX(.5rem);
  }

  75% {
    transform: translateX(-.5rem);
  }
}

.add-friend {
  width: 50%;
}

.add-friend h2 {
  margin: 0;
}

.add-friend .error {
  margin-top: .5rem;
  text-align: left;
}

.friends-outer h1 {
  margin: 0;
  margin-bottom: .2rem;
}

.friends-inner {
  display: flex;
  flex-direction: column-reverse;
}

.friends-list {
  width: 50%;
}

.friend {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .5rem;
  border-radius: 6px;
  margin-bottom: .1rem;
}

.friend i {
  font-size: 2rem;
}

.friend .profile-small {
  margin: 0;
}

.friend img {
  outline: none;
}

.friend:hover,
.friend.hover {
  background-color: rgb(243, 243, 243);
}

.filter-tabs .tab.selected {
  color: white;
  background-color: var(--color-primary);

}

.filter-tabs .tab:hover {
  position: relative;
  z-index: 2;
}

.filter-tabs .tab:hover:not(.green) {

  outline: 2px solid var(--color-primary);
}

.filter-tabs .tab.green:hover {

  outline: 2px solid #42ce59;
}

.filter-tabs .tab:active {}

.tab.green.selected {
  background-color: #42ce59;
  color: white;
  font-weight: 500;
  outline: 2px solid #42ce59;
}

#sidebar .channel-selector {
  width: 100%;
}

.channel-selector {
  user-select: none;
  cursor: pointer;
  width: 15em;
  display: flex !important;
  min-height: 3rem;
  max-height: 3rem;
  box-sizing: border-box;
  align-items: center;
  column-gap: .65rem;
  border-radius: 6px;

  padding: .4rem .5rem;
  color: #494e61;
  margin-top: .10rem;
  margin-bottom: .10rem;
  background-color: white;
}

*[hidden] {
  display: none !important;
}

.visibility-off {
  font-size: 1rem;
  margin-right: .2em;
  margin-bottom: -.2em;
}

.channel-selector.unread>* {
  font-weight: bold !important;

}


.channel-selector:hover .activity-status {
  outline-color: rgb(243, 243, 243);

}

.channel-selector.focused .activity-status {
  outline-color: rgb(243, 243, 243);
}

.channel-selector .profile-small {
  margin: 0;
}

.channel-selector .pfp {
  margin: 0;
}

.channel-selector .pfp img,
.channel-selector .pfp .img {
  outline: none;
  margin: 0;
}

.channel-selector i {
  font-size: 1.25rem;
  margin-left: .5rem;
  margin-top: .3rem;
}

.channel-selector.focused {
  color: black;
  background-color: var(--background-accent-color);
}

.channel-selector.focused:hover {

  filter: brightness(1.05);
}

.channel-selector:focus {
  z-index: 10;
}

.channel-selector:not(.focused):hover {

  background-color: rgb(248, 248, 248);
}

.channel-selector img {


  --size: 2.2rem;
  height: var(--size);
  width: var(--size);
  flex-shrink: 0;
  border-radius: 50%;
  background-color: lightgray;
}

.channel-selector .title {

  text-decoration: none !important;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

#pane {
  overflow: hidden;
  background-color: white;
  padding: var(--pane-padding-default) 0;
  padding-left: calc(var(--pane-padding-default));
  padding-right: calc(var(--pane-padding-default));
  border-radius: var(--outside-border-radius);

  display: flex;
  flex-direction: column;
  width: 100%;
}

#pane:has(.channel) {
  padding-left: 0;
}

#pane:has(.channel) .pane-topbar {
  padding-left: calc(var(--pane-padding-default));
}

.messages {
  display: flex;
  flex-direction: column-reverse;

  min-height: 0;
  overflow-y: scroll;
  height: 100%;
}

.message-skeleton-buffer {
  height: 50em;
  min-height: 50em;
  overflow: hidden;
  display: flex;
  flex-direction: column-reverse;
}

.message-skeleton-buffer .message *:not(.bar, .right, .left, .gutter) {
  border-radius: 3px;
  background-color: var(--background-accent-color);
  color: transparent;
}

.message-skeleton-buffer .bar {
  margin-bottom: .4rem;
}

.message-skeleton-buffer .content-image {
  width: 10em;
  height: 10em;
}

.message-skeleton-buffer .img {
  margin-right: .85rem;
  --size: 2.5rem;
  height: var(--size);
  width: var(--size);
  flex-shrink: 0;
  border-radius: 50% !important;
  margin-top: -.8rem;
  background-color: var(--background-accent-color) !important;
}

.message {
  position: relative;
  padding-top: .5rem;
  display: flex;
  padding-bottom: .5rem;
  padding-left: 1rem;

}

.message.system {
  display: flex;

  font-weight: 500;
}

.message.system .time {
  margin-left: .5rem;
  font-weight: 400;
}

.message.unviewed:not(:has(+ .unviewed)) {
  padding-top: 1rem;
}

.message.unviewed:not(:has(+ .unviewed))::after {
  content: 'New Messages';
  position: absolute;
  width: 8rem;
  text-align: center;
  left: calc(50% - 4rem);
  top: -.2rem;
  color: var(--color-primary);
  box-sizing: border-box;

}

.message.unviewed:not(:has(+ .unviewed))::before {
  content: '';
  --gap-size: 8%;
  position: absolute;
  top: .5rem;
  width: 90%;
  left: calc(50% - 45%);
  box-sizing: border-box;
  height: 1px;
  background-color: var(--color-primary);
  clip-path: polygon(calc(50% + var(--gap-size)) 0, calc(50% - var(--gap-size)) 0, calc(50% - var(--gap-size)) 100%, 0% 100%, 0 0, 100% 0, 100% 100%, calc(50% + var(--gap-size)) 100%);
}

.message.collapsed+.message {
  padding-bottom: 0;
}

.message.collapsed+.message {
  padding-bottom: 0;
}



.message.collapsed {
  padding-top: 0;
  padding-bottom: 0;
}

.message:hover,
.message.selected {
  background-color: #00000005;
}

.text-content {
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.text-content p {
  margin: 0;
  height: fit-content;
}

.text-content strong {
  font-weight: 500;
}

.text-content h1,
h2,
h3,
h4,
h5,
h6 {

  margin: 0;
}

.text-content pre:has(code) {


  padding: .2rem;
  background-color: #f3f4f8;
  margin: 0;
  margin-right: 1rem;
  margin-left: -.1rem;
  border-radius: 4px;
}

.text-content pre:has(code) code {
  background-color: unset;
}

.text-content code {
  background-color: #f3f4f8;

  padding: .1rem;
  border-radius: 4px;
}

.text-content hr {}



.message.temp {
  opacity: .5;
}

.message a {
  word-break: break-all;
}

.message .bar {
  display: flex;
  column-gap: .5rem;
  align-items: end;
}

.message .time {
  margin-bottom: -0.01rem;
  font-size: .9rem;
  opacity: .6;
}

.message>div:nth-child(1) {
  display: flex;
  align-items: center;
  margin-top: .2em;

}

.searcher .search-input-icon {
  font-size: 1.2rem;
  margin-left: .6rem;
  margin-right: -.4rem;
  margin-bottom: -.05rem;
}




.message-skeleton-buffer .message.collapsed {
  margin-top: 0rem;
}

.message .gutter {
  margin-right: .85rem;
  --size: 2.5rem;

  width: var(--size);
  flex-shrink: 0;


}

.attachments {
  display: flex;
  column-gap: .5rem;
  max-width: calc(100% - 4em);
}


.embed {
  max-width: 40em;
  cursor: pointer;
  max-width: min(25em, calc(100% - 4em));


  margin-top: .5rem;
}

.attachments:has(.raw) {
  max-width: none;
}

.embed:has(.raw) {

  max-width: none;
}

.embed:has(.message-file-embed) {
  max-width: none;
}

.embed .message-media-image {
  max-width: 100%;
  position: relative;
  border-radius: 5px;
  max-height: 100%;
  height: auto !important;
}

img {
  position: relative;
}

.message-media-image:not(.loaded)::after,
img.loading::after {
  content: '';
  left: 0;
  top: 0;
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgb(247, 247, 247), rgb(221, 221, 221), rgb(247, 247, 247));
  background-image: url(https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/cupertino_activity_indicator_large.gif);
  background-size: 2em;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--background-accent-color);

}

img.error {
  position: relative;
  overflow: hidden;
}

img.error::after {
  content: '';
  left: 0;
  top: 0;
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgb(247, 247, 247), rgb(221, 221, 221), rgb(247, 247, 247));
  background-image: url(https://cdn4.iconfinder.com/data/icons/ios-edge-glyph-9/25/Sad-Face-512.png);
  background-size: 1em;
  background-blend-mode: color-burn;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--background-accent-color);

}

.icon {
  margin-right: .3em;
  margin-bottom: -.3rem;
}


.message .pfp {
  flex-shrink: 0;
  margin-right: .85rem;
  --size: 2.5rem;
  height: var(--size);
  width: var(--size);
  min-width: var(--size);
  min-height: var(--size);
}

.message .pfp img,
.message .pfp .img {
  max-width: unset;
  max-height: unset;
  margin: 0;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: lightgray;
}



.messages .buffer {
  height: 0em;
  flex-shrink: 0;
}

.embed .raw {
  padding: .4rem 1rem;
  margin: .8rem 0;
  cursor: pointer;
  border-radius: 6px;


  display: flex;
  flex-direction: row-reverse;
  font-size: .9rem;
  align-items: center;
  border: 1px solid lightgray;
  column-gap: 1rem;
}

.embed .raw a {
  font-size: 1.2rem;
}

.form {
  height: fit-content;

  background-color: white;


  width: 20em;



}

.form.auth {
  overflow: hidden;
  margin: 1rem;
  padding: 2rem 2rem;
  border-radius: var(--outside-border-radius);
  box-shadow: 0 0 15px #24213523;
  animation: form-in .4s;
}

@keyframes form-in {
  0% {
    transform: translateX(0em) scale(1.1, 1.2) rotate(2deg);
  }

  100% {

    transform: translateX(0em);

  }
}

.form-validator-text:not(:empty) {
  display: block;
  color: rgb(236, 8, 8);
  font-size: .9rem;
  margin-top: -.5rem;
  margin-bottom: 1rem;

}

.form h1 {
  margin-top: -.5rem;
  margin-bottom: 2rem;
}

label {
  display: block;
  margin-bottom: -.3rem;
  opacity: .9;
  font-size: .85em;

}

.description-label {
  font-size: .8em;
  opacity: .9em;
  margin-top: -.2rem;
  margin-bottom: .8rem;
}

.oauth {
  /* display: none; */
}

.error {
  color: rgb(236, 8, 8);
  font-size: .9rem;
  font-weight: 400;
  text-align: center;
}
.active {
  color: #31b747;
  font-weight: 600;
  font-style: italic;
}
.italic {
  font-style: italic;
  font-weight: 600;
}

.divider-text {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider-text span {
  position: relative;
  z-index: 1;
  padding: 0 1rem;
  font-size: .9rem;
  background-color: white;
  color: gray
}

.divider-text::before {
  z-index: 0;
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: lightgray;
}

.w-button {
  margin-top: -1rem;
  margin-bottom: 1rem;
}

.input-w-button {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.input-w-button .input-wrapper {
  width: 100%;

}

.input-w-button .action-button {
  min-width: 8em;
  width: fit-content;
  margin: 0;
}

.input-w-inside-button {
  display: flex;
  align-items: center;
  column-gap: 1rem;

}

.input-w-inside-button .action-button {
  min-width: 6em;
  width: fit-content;
  min-height: 2rem;
  margin: 0;
  margin-right: .2rem;

}

.input-wrapper {
  --focus-color: var(--color-primary);
  background-color: var(--background-accent-color);
  min-height: 2.5rem;
  border-radius: var(--border-radius);
  margin: .65rem 0;
  display: flex;
  align-items: center;

  outline: 2px solid #00000000;
  outline-offset: 3px;
}

.input-wrapper input,
textarea {
  user-select: none;
  min-height: 2.5rem;
  border-radius: var(--border-radius);
  padding: 0 1rem;
  width: 100%;
  background-color: unset;
  resize: none;
  overflow-y: auto;
  border: unset;
  font-family: inherit;

}

textarea {
  min-height: 5em;
}

.input-wrapper:focus-within i {}



.searcher .searcher-close-icon {
  cursor: pointer;
  font-size: 1.3rem;
  margin-right: .5rem;
  margin-left: -.4rem;
}

.input-wrapper>i:first-child {
  margin-left: .7rem;
  margin-right: -.3rem;

}



.input-wrapper i.action {
  border-radius: 4px;
  color: white;
  font-size: 1.2rem;
  padding: .4rem 1rem;
  margin-right: .3rem;
  background-color: var(--color-primary);
}



.input-wrapper textarea {
  padding: .5rem 1rem;
}

input[disabled] {
  pointer-events: none !important
}

.input-wrapper.no-bg {
  background-color: transparent;
}

.input-wrapper:not(.disabled-plaintext):has(> input:disabled) {


  background-color: rgb(233, 233, 233);
  color: #494e61;
}



.input-wrapper:not(.keep-buttons):has(> input:disabled) .action-button {
  pointer-events: none;
  background-color: rgb(121, 121, 121);
  opacity: .5;
}

.input-wrapper.disabled-plaintext:has(> input:disabled),
.input-wrapper.disabled-plaintext input:disabled {
  background-color: transparent !important;
  color: black;
  padding: unset;
  margin: unset;
  min-height: unset;
  max-height: unset;

}

.input-wrapper.disabled-plaintext.see-input input:disabled {
  background-color: rgb(240, 240, 240);
  padding: 0 1rem;
}



.message-bar {
  position: relative;
  margin-right: 1.5rem;
  margin-left: var(--pane-padding-default);
  margin-top: 1rem;
  font-size: 1.2rem;
  background-color: var(--background-accent-color);
  flex-direction: column-reverse;
  min-height: fit-content;
  transition: height 200ms;
}

.message-bar.file-drop {
  outline: 2px dashed var(--color-primary);
  height: 6rem;
}



.message-bar textarea {
  box-sizing: border-box;
  min-height: unset;
  padding-top: .7rem;
  height: 2.5rem;


  overflow-y: visible;
}

.attached-media {
  column-gap: .3rem;
  padding: .5rem;
  padding-top: 0;
  padding-bottom: 0rem;
  height: fit-content;
  box-sizing: border-box;
  display: flex;
  width: 100%;
}


.message-file-embed {
  cursor: pointer;
  font-size: .9rem;
  padding: .5em;
  background-color: var(--background-accent-color);
  border-radius: 6px;
}

.message-file-embed embed {
  pointer-events: scroll;
  height: 15rem;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 19rem;

  margin-top: .5rem;

  overflow: hidden;
}

.message-media-preview,
.embed {

  width: fit-content;
  display: inline-block;
  position: relative;
}

.message-media-preview i,
.embed i {
  --size: 1.4rem;
  width: var(--size);
  height: var(--size);
  position: absolute;
  right: 0;
  top: .5rem;
  flex-shrink: 0;
  flex-grow: 0;
  font-size: .6rem;
  margin: .2rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #00000082;

  border-radius: 50%;

}

.message-media-preview i svg,
.embed i svg {
  position: absolute;
  width: 100%;
  flex-shrink: 0;
  flex-grow: 0;

  opacity: .9;
}

.attached-media:empty {
  padding: 0;
}

.attached-media img {
  margin-top: .5rem;
  border-radius: 5px;
  object-fit: cover;
  max-width: 10em;
  max-height: 10em;
}

.message-bar input {
  font-size: .9rem;
}

.input-wrapper:not(.message-bar):has(input:focus) {
  outline: 2px solid var(--color-primary);
  transition: outline 200ms;

}

.input-wrapper:has(input:invalid),
.input-wrapper:has(input:aria-invalid) {
  outline: 2px solid rgb(236, 8, 8) !important;
}

input:aria-invalid,
input:invalid {
  background-color: red;
}

.input-wrapper input:focus,
textarea:focus {
  outline: none;
}

.oauth-provider {
  padding: 0rem 1rem;
  margin: .8rem 0;
  cursor: pointer;
  border-radius: 6px;
  min-height: 2.5rem;
  max-height: 2.5rem;
  display: flex;
  font-size: .9rem;
  align-items: center;
  border: 1px solid lightgray;
}

.form-description {
  margin-top: -.6rem;
  margin-bottom: 1.5rem;
}

.input-wrapper .action-button:last-child {
  width: unset !important;
  padding: .3rem 1rem !important;
  margin-right: .2rem;
  display: flex;
  min-height: 2.1rem;
  justify-content: center;
  min-width: unset;
  width: 6rem;
}

button {
  border: none;
}

.action-button {
  user-select: none;
  background-color: var(--color-primary);
  border: 0;
  font-weight: 600;
  cursor: pointer;
  color: white;
  font-family: inherit;
  min-height: 2.5rem;
  max-height: 2.5rem;
  border-radius: 4px;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2.2rem 0;
  font-size: .9rem;
  width: 100%;
  box-sizing: border-box;
  transition: opacity 200ms;
  text-decoration: none !important;
  --focus-color: var(--color-primary);
}

.action-button:hover {
  opacity: .8;
}

* {
  outline-color: var(--focus-color);
}

*:focus-visible {
  outline: 2px solid var(--focus-color);
  transition: outline-color 100ms;
  outline-offset: 3px;
}

.action-button:disabled {
  cursor: default;
  background-color: #dddddd;
}

.action-button:disabled .loader {
  display: inline-block;
}

.action-button:disabled .hide-disable {
  display: none;
}

.action-button.secondary {
  background-color: unset;
  color: inherit;
}

.action-button.secondary-grey {
  background-color: #ecedf1;
  color: rgb(46, 46, 46);
}

.action-button.red {
  background-color: rgb(172, 0, 0);
  --focus-color: rgb(172, 0, 0);
}


.auth-wrong-menu {
  display: block;
  margin-top: 2.5rem;
  font-size: .95em;
  text-align: center;

}

.badge {
  position: relative;
  margin-right: .5rem !important;
}




.badge::after {
  font-family: Arial;
  font-size: .8rem;
  font-weight: 400;
  --size: 1.2rem;
  content: attr(badge);
  color: white;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary);
  position: absolute;
  top: calc(var(--size) * -1/2);
  right: calc(var(--size) * -1/2);
  z-index: 10;
  outline: 2px solid white;
  width: var(--size);
  height: var(--size);
}

.badge-left.badge::after {
  top: calc(var(--size) * -5/8);
  left: calc(var(--size) * -3/4);
  right: unset;
}





.badge.secondary::after {
  color: #999;
  background-color: var(--background-accent-color);
}

.badge.alert::after {
  background-color: rgb(236, 8, 8);
}

.badge[badge='NONE'] {
  margin-right: 0 !important;
}

.badge[badge='NONE']::after {

  display: none;
}

.sidebar-opposites {
  display: flex;
  height: 100%;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100%;
  min-width: 0;

}

.sidebar-opposites>div:nth-child(1) {
  max-height: 100%;
  min-width: 0;
  flex-grow: 0;
  overflow: hidden;
  padding-top: .2rem;

}

.sidebar-opposites>div:nth-child(2) {
  flex-shrink: 0;
  margin-top: .5rem;
}

.flex-pusher-vert {
  height: 100%;


}

.settings {
  display: flex;
  max-height: 100%;
}

.settings-outer {
  overflow-y: auto;
  width: 100%;
  overflow-x: visible;
  padding-left: .5rem;
  margin-left: -1rem;
}

.settings-sidebar {
  display: flex;
  flex-direction: column;
  border-right: 1px solid lightgray;
  margin-right: 1.8rem;
  padding-right: 1.8rem;
}

.chat-loader {
  font-size: 6rem;
  height: 1em;
  width: 1em;
  overflow: visible;
  position: relative;
  --bubble-size-x: .8em;
  --bubble-size-y: .55em;
  --bubble-size: var(--bubble-size-x) var(--bubble-size-y);
  --bubble-color: #FFF;
  --bubble-outline-scale: 1.2;
  --bubble-outline-size-x: calc(var(--bubble-size-x) * var(--bubble-outline-scale));
  --bubble-outline-size-y: calc(var(--bubble-size-y) * var(--bubble-outline-scale));
}

.chat-loader.color {
  --bubble-color: var(--color-primary);
}

/* Custom Properties for Animating Mask Position */
@property --mask1-x {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 0%;
}

@property --mask1-y {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 0%;
}

@property --mask2-x {
  syntax: '<percentage>';
  inherits: false;
  initial-value: -250%;
}

@property --mask2-y {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 200%;
}

/* Shared styles */
.chat-loader::before,
.chat-loader::after {
  content: '';
  width: 70%;
  height: 100%;
  background-color: var(--bubble-color);
  mask-position: var(--mask1-x) var(--mask1-y), var(--mask2-x) var(--mask2-y);
  mask-repeat: no-repeat;
  mask-size: var(--bubble-size), calc(var(--bubble-size-x)* var(--bubble-outline-scale)) calc(var(--bubble-size-y) * var(--bubble-outline-scale));
  display: block;
  position: absolute;

  transition: background-color 1s;
}

/* First Element */
.chat-loader::before {
  mask-image: url('/public/chat-reflected.svg'), url('/public/chat-outline.svg');
  mask-composite: subtract;

  left: 0;
  animation: chat-loader-mask1-move 2s -1s infinite cubic-bezier(0, .72, 1, .43),
    chat-loader-mask2-move 2s infinite cubic-bezier(0, .72, 1, .43);
}

/* Second Element */
.chat-loader::after {
  mask-image: url('/public/chat.svg');
  right: 0;

  animation: chat-loader-mask1-move 2s infinite cubic-bezier(0, .72, 1, .43)
}

/* First Mask Animation */
@keyframes chat-loader-mask1-move {
  0% {
    transform: scale(0);
    --mask1-x: 0%;
    --mask1-y: 200%;
  }

  5% {
    transform: scale(1);
  }

 
  95% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
    --mask1-x: 0%;
    --mask1-y: -100%;
  }
}

/* calc(50% - half the width) */

/* Second Mask Animation */
@keyframes chat-loader-mask2-move {
  0% {
    --mask2-x: calc(-50% / calc(var(--bubble-outline-scale) / 2));
    --mask2-y: calc(200% * calc(var(--bubble-outline-scale)));
  }


  100% {
    --mask2-x: calc(-50% / calc(var(--bubble-outline-scale) / 2));
    --mask2-y: calc(-100% * calc(var(--bubble-outline-scale)));
  }
}


/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 4px solid var(--color-primary);
  animation:
    l20-1 0.8s infinite linear alternate,
    l20-2 1.6s infinite linear;
}

.loader-2 {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 4px solid lightgray;
  animation:
    l20-1 0.8s infinite linear alternate,
    l20-2 1.6s infinite linear;
}

.action-button .loader {
  display: none;
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 3px solid white;
  animation:
    l20-1 0.8s infinite linear alternate,
    l20-2 1.6s infinite linear;

}

.text-transparent {
  color: transparent !important;
}

.action-button:disabled .enabled-only {
  display: none !important;
}



i .loader {
  display: none;
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 3px solid white;
  animation:
    l20-1 0.8s infinite linear alternate,
    l20-2 1.6s infinite linear;

}



@keyframes l20-1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%)
  }

  12.5% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%)
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%)
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%)
  }

  62.5% {
    clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%)
  }

  75% {
    clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%)
  }

  100% {
    clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%)
  }
}

@keyframes l20-2 {
  0% {
    transform: scaleY(1) rotate(0deg)
  }

  49.99% {
    transform: scaleY(1) rotate(135deg)
  }

  50% {
    transform: scaleY(-1) rotate(0deg)
  }

  100% {
    transform: scaleY(-1) rotate(-135deg)
  }
}

.context-menu {
  position: absolute;
  background-color: white;
  box-shadow: 0 0 15px #24213523;
  width: fit-content;
  min-width: 12em;
  padding: .5rem 0;
  font-size: .85rem;
  box-sizing: border-box;
  border-radius: 6px;
  z-index: 1000;

  animation: modal-in .2s;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

}

.context-menu .context-label-container {
  display: flex;
  justify-content: space-between;
}

.context-keybind {
  opacity: .5;
}

.context-menu hr {
  margin: .5rem .5rem;

  border: none;
  border-bottom: 1px solid #ecedf1;
}

.context-menu .title {
  padding: .5rem 1rem;
  padding-top: .1rem;
  border-bottom: 1px solid #ecedf1;
  margin-bottom: .5rem;
}

.context-menu .title:empty {
  display: none;
}

.context-menu .context-item {
  cursor: pointer;
  padding: .5rem 1rem;

}

.context-menu .context-item:hover,
.context-menu .context-item.hover {
  background-color: rgb(243, 243, 243);
}

.modal-outer {
  content: '';
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0vw;
  left: 0vh;
  z-index: 1000000;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: modal-outer-in .2s;
}

@keyframes modal-outer-in {
  0% {
    opacity: 0;
  }
}

.modal {

  background-color: white;
  box-shadow: 0 0 15px #24213523, 0 0 0px 99999px #22212c42;
  overflow: hidden;
  width: min-content;
  min-width: 28rem;
  z-index: 10000000;
  border-radius: 6px;
  animation: modal-in .3s;
}

@keyframes modal-in {
  0% {
    transform: translateY(-2em) rotate(2deg);
    opacity: 0;
  }
}



.modal .title {
  margin-bottom: .5rem;
  padding: .7rem 1rem;
  background-color: #ecedf1;
  font-weight: 500;

  opacity: .5;
}

.modal .description {
  margin-bottom: .5rem;
  padding: 1rem 1rem;
  white-space: pre-line;

}

.modal .action-bar {
  display: flex;
  align-items: center;
  justify-content: end;
  padding: .5rem 1rem;
  margin-bottom: .65rem;
  column-gap: .5rem;
}

.modal .action-button {
  margin: 0;
}

.buttons {
  display: flex;
  column-gap: .5rem;
}

.round-button {
  cursor: pointer;
  font-size: 2.2rem;
  color: var(--color-primary);
}

.round-button.secondary {
  opacity: .5;
}

.round-button:hover {
  filter: brightness(1.5);
}

.sidebar-mover {
  transition: 400ms;
  height: 100%;
  max-height: 100%;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: column;
  flex-grow: 0;
  overflow: hidden;
  min-width: 0;
 
}

.sidebar-mover.true {
 
  transform:skew(-16deg) translateY(100%);
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  
}

.sidebar-mover-opposite {
  visibility: hidden;
  transition: 200ms;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.sidebar-mover-opposite.true {
  opacity: 1;
  height: auto;
  visibility: visible;
}

.searcher {
  --animation-length: 150ms;
  --animation-delay-per-item: 50ms;
}

.searcher *:nth-child(1) {
  opacity: 0;
  animation: in var(--animation-length) forwards;
}

.searcher *:nth-child(2) {
  opacity: 0;
  animation: in var(--animation-length) calc(var(--animation-delay-per-item) * 1) forwards;
}

.searcher *:nth-child(3) {
  opacity: 0;
  animation: in var(--animation-length) calc(var(--animation-delay-per-item) * 2) forwards;
}

.searcher *:nth-child(4) {
  opacity: 0;
  animation: in var(--animation-length) calc(var(--animation-delay-per-item) * 3) forwards;
}

.searcher *:nth-child(5) {
  opacity: 0;
  animation: in var(--animation-length) calc(var(--animation-delay-per-item) * 4) forwards;
}

.searcher *:nth-child(6) {
  opacity: 0;
  animation: in var(--animation-length) calc(var(--animation-delay-per-item) * 5) forwards;
}

.searcher *:nth-child(7) {
  opacity: 0;
  animation: in var(--animation-length) calc(var(--animation-delay-per-item) * 6) forwards;
}

.searcher *:nth-child(8) {
  opacity: 0;
  animation: in var(--animation-length) calc(var(--animation-delay-per-item) * 7) forwards;
}

.searcher *:nth-child(9) {
  opacity: 0;
  animation: in var(--animation-length) calc(var(--animation-delay-per-item) * 8) forwards;
}

.searcher *:nth-child(10) {
  opacity: 0;
  animation: in var(--animation-length) calc(var(--animation-delay-per-item) * 9) forwards;
}

.searcher *:nth-child(11) {
  opacity: 0;
  animation: in var(--animation-length) calc(var(--animation-delay-per-item) * 10) forwards;
}

.searcher *:nth-child(12) {
  opacity: 0;
  animation: in var(--animation-length) calc(var(--animation-delay-per-item) * 11) forwards;
}

.searcher *:nth-child(13) {
  opacity: 0;
  animation: in var(--animation-length) calc(var(--animation-delay-per-item) * 12) forwards;
}

.searcher *:nth-child(14) {
  opacity: 0;
  animation: in var(--animation-length) calc(var(--animation-delay-per-item) * 13) forwards;
}

@keyframes in {
  0% {
    transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
  }
}

.searcher {
  flex-shrink: 0;
  margin-bottom: .25rem;
  transition-duration: 200ms;
  height: 3em;
}

.searcher .input-wrapper {
  margin: 0;
  margin-top: .15rem;

}



.searcher .profile-small:hover {

  background-color: rgb(243, 243, 243);

}

.list-profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .5rem;
  border-radius: 6px;
  margin-bottom: .1rem;
}

.list-profile .profile-small {
  margin: 0;
}

.list-profile img {
  outline: none;
}

.list-profile:hover,
.list-profile.hover {
  background-color: rgb(243, 243, 243);
}


.selection-modal {
  position: absolute;
  left: calc(50% - 15em);
  animation: modal-in .3s;
  width: 30em;
  height: 25em;
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 200;
  box-shadow: 0 0 15px #24213523, 0 0 0px 99999px #22212c42;
  border-radius: var(--outside-border-radius);
  padding: 1rem;
  padding-top: .3rem;
}

.selection-modal .values>div {
  outline: 2px solid transparent;
}

.selection-modal .values>div:focus-visible {
  outline: 2px solid var(--color-primary);
  background-color: #ecedf1;
}

.selection-modal .values>div:hover {
  background-color: #ecedf1;
}

.selection-modal h3 {
  margin: 0;
  font-size: 1.5rem;
}

.selection-modal .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: .7rem;
  margin-bottom: 0.2rem;
}

.selection-modal .top i {
  cursor: pointer;
  font-size: .9rem;
  user-select: none;
}

.row {
  display: flex;
  align-items: baseline;
  column-gap: .5rem;
}

.selection-modal .values {
  overflow-y: auto;
  overflow-x: visible;
  height: 100%;
  padding: 4px;
}

.selection-modal .input-wrapper {
  flex-shrink: 0;
  margin-bottom: 1rem;
  user-select: none;
}

.selection-modal .action-button {
  margin: 0;
  margin-top: .5rem;
}

.dropdown-selections {
  display: flex;
  flex-wrap: wrap;
  row-gap: .1rem;
  column-gap: .2rem;
  padding: .4em 0em;
  margin-right: .5rem;
  user-select: none;

}

.input-wrapper:has(.dropdown-selections) {
  flex-wrap: wrap;
  padding: 0 .5rem;
}

.dropdown-selections+input {
  width: auto;
  padding: 0;
}

.dropdown-selection {
  user-select: none;
  font-size: .9rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: .3rem;
  color: black;
  padding: .2rem 1rem;
  padding-left: 2rem;
  border-radius: 40px;
  text-align: center;
  background-color: white;
  transition: transform, outline 50ms;
  outline: 2px solid #00000000;

  outline-offset: 2px;
  position: relative;

}

.dropdown-selection:focus-visible,
.dropdown-selection:focus-visible {
  outline: 2px solid var(--color-primary);
}

.dropdown-selection img,
.dropdown-selection .pfp {
  height: 1.4rem;
  width: 1.4rem;
  top: calc(50% - 1.4rem / 2);
  position: absolute;
  left: 0.1rem;
  border-radius: 50%;
  aspect-ratio: 1/1;
}

.dropdown-selection .pfp .img {
  height: 1.4rem;
  width: 1.4rem;
}

.dropdown-selection .x {
  cursor: pointer;

  border-radius: 50%;
  display: inline-block;
  height: 1rem;
  font-size: 1.1rem;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  margin-right: -.5rem;
}

.selection-modal .profile-small {
  border-radius: 6px;
  padding: .4rem .5rem;
  margin: 0 0;
  margin-right: .5rem;
  user-select: none;
}

.selection-modal .profile-small:hover {
  background-color: #ecedf1;

}

.selection-modal input[type='checkbox'] {
  margin-right: .65rem;

}

input[type='checkbox'] {
  appearance: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border-radius: 4px;
  width: 1.7rem;
  height: 1.7rem;
  transition: 200ms;

}

*:focus-visible>input[type='checkbox'] {
  background-color: rgba(78, 61, 224, .3);
}

*:hover>input[type='checkbox'] {
  background-color: rgba(78, 61, 224, .3);

}

input[type='checkbox']:focus-visible {
  background-color: rgba(78, 61, 224, .3);
}

input[type='checkbox']:hover {
  background-color: rgba(78, 61, 224, .3);
}

input[type='checkbox']::before {
  content: '';
  position: absolute;
  cursor: pointer;
  appearance: none;
  width: 1.2rem;
  height: 1.2rem;
  border: 1px solid #dddddd;
  border-radius: 3px;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

/* input[type='checkbox']:before {
  content: '';
  position: absolute;
  opacity: 0;
  background-color: var(--color-primary);
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  transition: 200ms;
}

input[type='checkbox']:hover:before {
 
  opacity: .2;
  background-color: var(--color-primary);
 
} */

input[type='checkbox']::after {
  content: '✔';
  color: transparent;
  transform: rotate(90deg);
  transition: transform 200ms cubic-bezier(0, 1.18, .43, 1.56)
}

input[type='checkbox']:checked::after {
  color: white;
  transform: rotate(0deg);


}


input[type='checkbox']:checked::before {
  background-color: var(--color-primary);

}

.circular-progress {
  --size: 250px;
  --half-size: calc(var(--size) / 2);
  --stroke-width: 25px;
  --radius: calc((var(--size) - var(--stroke-width)) / 2);
  --circumference: calc(var(--radius) * pi * 2);
  --dash: calc((var(--progress) * var(--circumference)) / 100);


}

.circular-progress circle {
  cx: var(--half-size);
  cy: var(--half-size);
  r: var(--radius);
  stroke-width: var(--stroke-width);
  fill: none;

}

.circular-progress circle.bg {
  stroke: #888;
}

.circular-progress circle.fg {
  transform: rotate(-90deg);
  transform-origin: var(--half-size) var(--half-size);
  stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
  transition: stroke-dasharray 0.3s linear 0s;
  stroke: #ffffff;
}

.flip-180 {
  transform: rotate(180deg);
}





@keyframes progress-animation {
  0% {
    --progress: 0;
  }

  100% {
    --progress: 100;
  }
}

@media screen and (max-width: 800px) {
  .channel-info {
    display: none !important;
  }

  .messages-outer {
    width: 100%;
  }
}

.top-right-actions {


  display: flex;
  justify-content: end;

}

.channel-selector {
  position: relative;
}

.channel-selector .top-right-actions {
  position: absolute;
  right: 0rem;
  display: none;
  z-index: 20;
  margin-right: .5rem;
}

.channel-selector .action-circle {
  margin: 0em !important;
  background-color: transparent;
  color: #494e61;
  font-size: 1rem !important;
}

.cursor {
  cursor: pointer;
}

.channel-selector .action-circle:hover {
  background-color: #00000015;
}

.channel-selector:hover .top-right-actions {
  display: flex;
}

.profile .top-right-actions {
  position: absolute;
  right: .2rem;
  top: .2rem;
  z-index: 20;
}

.action-circle {
  --size: 1.4rem;
  width: var(--size);
  height: var(--size);


  flex-shrink: 0;
  flex-grow: 0;
  font-size: .65rem !important;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;

  margin: .2rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #00000082;
  border-radius: 50%;
}

#pane:has(.call:not(.inwindow)) {
  position: relative;
}

#pane:has(.call:not(.inwindow)) .pane-topbar {
  position: absolute;
  padding: var(--pane-padding-default) var(--pane-padding-default)var(--pane-padding-default)var(--pane-padding-default);
}

#pane:has(.call:not(.inwindow)) {
  padding: 0;

}

.call {



  display: grid;
  grid-template-rows: 1fr auto;
  row-gap: 1rem;
  padding: 3.5em var(--pane-padding-default)var(--pane-padding-default)var(--pane-padding-default);
  height: 15em;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  background-color: var(--background-accent-color);
  grid-row: 1;
  grid-column: 1 / 3;

}

.call.inwindow {
  position: absolute;
  top: 0;
  right: 0;
  width: 20em;
  height: auto;
  aspect-ratio: 16/9;
  z-index: 5;
  padding: 0rem;
  border-radius: 5px;

  cursor: grab;
  background-color: transparent;
  border: none;
  row-gap: 0;
  box-shadow: 0 0 5px #00000082;
  
  user-select: none;
  will-change: transform;
  transition: transform 50ms;
  background-color: #f3f4f8;
}
.call.inwindow .actions {
  position: absolute;
  background-color: #0d122266;
  border-radius: 40px;
  padding: .25rem .25rem;
  left: 50%;
  /* top: calc(100% + .3rem);  */
  bottom: .25rem;
  transform: translateY(100%) translateX(-50%);
  clip-path: inset(0 0 100% 0);

  display: flex;
  column-gap: .5rem;
  transition: transform 200ms, clip-path 200ms;
}
.call.inwindow:hover .actions {
  transform: translateY(0) translateX(-50%);
  clip-path: inset(0 0 0 0);
}
.call.inwindow .actions .action-button {
  --size: 2rem;
  
 
}

.call.inwindow .actions .action-button span {
  font-size: 1.2em !important;
}


.dragging {
  opacity: 0.8;
  cursor: grabbing;
}
.call.inwindow .participants:has(.participant + .participant) {
  justify-content: space-around;
 
  padding: .5rem !important;
  column-gap: .5rem;
  row-gap: .5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;

}

.call.inwindow .participants:has(.participant + .participant) .participant {
  width: 100%;

  justify-self: center;

}

.call.inwindow .participants:has(.participant + .participant) .participant .pfp img {
  --size: 3rem;
}

.call .action-bar {
  column-gap: 1rem;
}

.participants {
  display: flex;
  column-gap: .8rem;
  justify-content: center;

}

.action-button .icon {
  margin: 0;
}

.call .action-button {
  --size: 3.5rem;

  color: white;
  border-radius: var(--size);
  height: var(--size);
  min-width: var(--size);

  cursor: pointer;
  margin: 0;
  min-width: var(--size);
  min-height: var(--size);
  width: fit-content;
  height: var(--size);
}

.action-button.secondary {
  background-color: #d9dadf;
  color: #494e61;
}

.action-button.secondary.active {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}

.action-button.join {
  padding: .6rem 1rem;

  background-color: var(--color-primary);
}

.action-button.join .icon {
  margin-right: .5rem;

}

.action-button.leave {
  color: white;
  background-color: var(--color-warning);
}

.participant {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightgray;
  border-radius: 5px;
  aspect-ratio: 16/9;
  width: 20em;
  max-width: max-content;
  max-height: max-content;
  transition: outline 100ms;
  outline: 2px solid rgba(78, 61, 224, 0);

}

.participant .pfp img {
  --size: 4rem
}

.participant video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

}

.participant.speaking {
  outline: 2px solid var(--color-primary);
  outline-offset: 3px;
}

.call .pfp .icon {
  font-size: 1.1rem;
  background-color: #0d122266;
  border-radius: 50%;
  padding: .3rem;
  color: white;
  position: absolute;
  bottom: .5rem;
  right: 0;
  z-index: 2;
}